import { AdapterBlueprint } from '../adapters/ChainAdapterBlueprint.js';
import { WcHelpersUtil } from '../utils/index.js';
import { ChainController, CoreHelperUtil, ConnectionController, OptionsController } from '@reown/appkit-core';
import bs58 from 'bs58';
import { ConstantsUtil } from '@reown/appkit-common';
export class UniversalAdapter extends AdapterBlueprint {
  constructor(options) {
    super(options);
  }
  async connectWalletConnect(onUri) {
    const connector = this.connectors.find(c => c.type === 'WALLET_CONNECT');
    const provider = connector?.provider;
    if (!this.caipNetworks || !provider) {
      throw new Error('UniversalAdapter:connectWalletConnect - caipNetworks or provider is undefined');
    }
    if (OptionsController.state.useInjectedUniversalProvider && ConnectionController.state.wcUri) {
      onUri(ConnectionController.state.wcUri);
      return;
    }
    provider.on('display_uri', uri => {
      onUri(uri);
    });
    const namespaces = WcHelpersUtil.createNamespaces(this.caipNetworks);
    await provider.connect({
      optionalNamespaces: namespaces
    });
  }
  async connect(params) {
    return Promise.resolve({
      id: 'WALLET_CONNECT',
      type: 'WALLET_CONNECT',
      chainId: Number(params.chainId),
      provider: this.provider,
      address: ''
    });
  }
  async disconnect() {
    const connector = this.connectors.find(c => c.id === 'WALLET_CONNECT');
    const provider = connector?.provider;
    await provider?.disconnect();
  }
  async getAccounts({
    namespace
  }) {
    const provider = this.provider;
    const addresses = provider?.session?.namespaces?.[namespace]?.accounts?.map(account => {
      const [,, address] = account.split(':');
      return address;
    }).filter((address, index, self) => self.indexOf(address) === index);
    return Promise.resolve({
      accounts: addresses.map(address => CoreHelperUtil.createAccount(namespace, address, namespace === 'bip122' ? 'payment' : 'eoa'))
    });
  }
  async syncConnectors() {
    return Promise.resolve();
  }
  async getBalance() {
    return Promise.resolve({
      balance: '0',
      decimals: 0,
      symbol: ''
    });
  }
  async signMessage(params) {
    const {
      provider,
      message,
      address
    } = params;
    if (!provider) {
      throw new Error('UniversalAdapter:signMessage - provider is undefined');
    }
    let signature = '';
    if (ChainController.state.activeCaipNetwork?.chainNamespace === ConstantsUtil.CHAIN.SOLANA) {
      const response = await provider.request({
        method: 'solana_signMessage',
        params: {
          message: bs58.encode(new TextEncoder().encode(message)),
          pubkey: address
        }
      }, ChainController.state.activeCaipNetwork?.caipNetworkId);
      signature = response.signature;
    } else {
      signature = await provider.request({
        method: 'personal_sign',
        params: [message, address]
      }, ChainController.state.activeCaipNetwork?.caipNetworkId);
    }
    return {
      signature
    };
  }
  // -- Transaction methods ---------------------------------------------------
  /**
   *
   * These methods are supported only on `wagmi` and `ethers` since the Solana SDK does not support them in the same way.
   * These function definition is to have a type parity between the clients. Currently not in use.
   */
  async estimateGas() {
    return Promise.resolve({
      gas: BigInt(0)
    });
  }
  async getProfile() {
    return Promise.resolve({
      profileImage: '',
      profileName: ''
    });
  }
  async sendTransaction() {
    return Promise.resolve({
      hash: ''
    });
  }
  async writeContract() {
    return Promise.resolve({
      hash: ''
    });
  }
  async getEnsAddress() {
    return Promise.resolve({
      address: false
    });
  }
  parseUnits() {
    return 0n;
  }
  formatUnits() {
    return '0';
  }
  async getCapabilities() {
    return Promise.resolve({});
  }
  async grantPermissions() {
    return Promise.resolve({});
  }
  async revokePermissions() {
    return Promise.resolve('0x');
  }
  async syncConnection() {
    return Promise.resolve({
      id: 'WALLET_CONNECT',
      type: 'WALLET_CONNECT',
      chainId: 1,
      provider: this.provider,
      address: ''
    });
  }
  // eslint-disable-next-line @typescript-eslint/require-await
  async switchNetwork(params) {
    const {
      caipNetwork
    } = params;
    const connector = this.connectors.find(c => c.type === 'WALLET_CONNECT');
    const provider = connector?.provider;
    if (!provider) {
      throw new Error('UniversalAdapter:switchNetwork - provider is undefined');
    }
    provider.setDefaultChain(`${caipNetwork.chainNamespace}:${String(caipNetwork.id)}`);
  }
  getWalletConnectProvider() {
    const connector = this.connectors.find(c => c.type === 'WALLET_CONNECT');
    const provider = connector?.provider;
    return provider;
  }
}
