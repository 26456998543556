import { AdapterBlueprint } from '@reown/appkit/adapters';
import { CoreHelperUtil } from '@reown/appkit-core';
import { connect, disconnect as wagmiDisconnect, createConfig, getConnections, switchChain, injected, watchAccount, watchConnections, getBalance, getEnsName, getEnsAvatar, signMessage, estimateGas as wagmiEstimateGas, sendTransaction as wagmiSendTransaction, getEnsAddress as wagmiGetEnsAddress, writeContract as wagmiWriteContract, waitForTransactionReceipt, getAccount, prepareTransactionRequest, reconnect, watchPendingTransactions, watchConnectors } from '@wagmi/core';
import '@wagmi/core/chains';
import { ConstantsUtil as CommonConstantsUtil, isReownName, NetworkUtil } from '@reown/appkit-common';
import { authConnector } from './connectors/AuthConnector.js';
import { AppKit, WcHelpersUtil } from '@reown/appkit';
import { walletConnect } from './connectors/UniversalConnector.js';
import { coinbaseWallet } from '@wagmi/connectors';
import { ConstantsUtil as CoreConstantsUtil } from '@reown/appkit-core';
import { CaipNetworksUtil, PresetsUtil } from '@reown/appkit-utils';
import { formatUnits, parseUnits } from 'viem';
import { normalize } from 'viem/ens';
import { parseWalletCapabilities } from './utils/helpers.js';
import { LimitterUtil } from './utils/LimitterUtil.js';
const DEFAULT_PENDING_TRANSACTIONS_FILTER = {
  enable: false,
  pollingInterval: 30000
};
export class WagmiAdapter extends AdapterBlueprint {
  constructor(configParams) {
    super({
      projectId: configParams.projectId,
      networks: CaipNetworksUtil.extendCaipNetworks(configParams.networks, {
        projectId: configParams.projectId,
        customNetworkImageUrls: {},
        customRpcChainIds: configParams.transports ? Object.keys(configParams.transports).map(Number) : []
      })
    });
    this.adapterType = 'wagmi';
    this.pendingTransactionsFilter = {
      ...DEFAULT_PENDING_TRANSACTIONS_FILTER,
      ...(configParams.pendingTransactionsFilter ?? {})
    };
    this.namespace = CommonConstantsUtil.CHAIN.EVM;
    this.createConfig({
      ...configParams,
      networks: CaipNetworksUtil.extendCaipNetworks(configParams.networks, {
        projectId: configParams.projectId,
        customNetworkImageUrls: {},
        customRpcChainIds: configParams.transports ? Object.keys(configParams.transports).map(Number) : []
      }),
      projectId: configParams.projectId
    });
    this.setupWatchers();
  }
  async getAccounts(params) {
    const connector = this.getWagmiConnector(params.id);
    if (!connector) {
      return {
        accounts: []
      };
    }
    if (connector.id === CommonConstantsUtil.CONNECTOR_ID.AUTH) {
      const provider = connector['provider'];
      const {
        address,
        accounts
      } = await provider.connect();
      return Promise.resolve({
        accounts: (accounts || [{
          address,
          type: 'eoa'
        }]).map(account => CoreHelperUtil.createAccount('eip155', account.address, account.type))
      });
    }
    const {
      addresses,
      address
    } = getAccount(this.wagmiConfig);
    return Promise.resolve({
      accounts: (addresses || [address])?.map(val => CoreHelperUtil.createAccount('eip155', val || '', 'eoa'))
    });
  }
  getWagmiConnector(id) {
    return this.wagmiConfig.connectors.find(c => c.id === id);
  }
  createConfig(configParams) {
    this.caipNetworks = configParams.networks;
    this.wagmiChains = this.caipNetworks.filter(caipNetwork => caipNetwork.chainNamespace === CommonConstantsUtil.CHAIN.EVM);
    const transportsArr = this.wagmiChains.map(chain => [chain.id, CaipNetworksUtil.getViemTransport(chain)]);
    Object.entries(configParams.transports ?? {}).forEach(([chainId, transport]) => {
      const index = transportsArr.findIndex(([id]) => id === Number(chainId));
      if (index === -1) {
        transportsArr.push([Number(chainId), transport]);
      } else {
        transportsArr[index] = [Number(chainId), transport];
      }
    });
    const transports = Object.fromEntries(transportsArr);
    const connectors = [...(configParams.connectors ?? [])];
    this.wagmiConfig = createConfig({
      ...configParams,
      chains: this.wagmiChains,
      transports,
      connectors
    });
  }
  setupWatchPendingTransactions() {
    if (!this.pendingTransactionsFilter.enable || this.unwatchPendingTransactions) {
      return;
    }
    this.unwatchPendingTransactions = watchPendingTransactions(this.wagmiConfig, {
      pollingInterval: this.pendingTransactionsFilter.pollingInterval,
      onError: () => {},
      onTransactions: () => {
        this.emit('pendingTransactions');
        LimitterUtil.increase('pendingTransactions');
      }
    });
    const unsubscribe = LimitterUtil.subscribeKey('pendingTransactions', val => {
      if (val >= CommonConstantsUtil.LIMITS.PENDING_TRANSACTIONS) {
        this.unwatchPendingTransactions?.();
        unsubscribe();
      }
    });
  }
  setupWatchers() {
    watchAccount(this.wagmiConfig, {
      onChange: (accountData, prevAccountData) => {
        if (accountData.status === 'disconnected') {
          this.emit('disconnect');
        }
        if (accountData.status === 'connected') {
          if (accountData.address !== prevAccountData?.address || prevAccountData.status !== 'connected') {
            this.setupWatchPendingTransactions();
            this.emit('accountChanged', {
              address: accountData.address
            });
          }
          if (accountData.chainId !== prevAccountData?.chainId) {
            this.emit('switchNetwork', {
              address: accountData.address,
              chainId: accountData.chainId
            });
          }
        }
      }
    });
    watchConnections(this.wagmiConfig, {
      onChange: connections => {
        if (connections.length === 0) {
          this.emit('disconnect');
        }
      }
    });
  }
  addWagmiConnectors(options, appKit) {
    const customConnectors = [];
    if (options.enableCoinbase !== false) {
      customConnectors.push(coinbaseWallet({
        version: '4',
        appName: options.metadata?.name ?? 'Unknown',
        appLogoUrl: options.metadata?.icons[0] ?? 'Unknown',
        preference: options.coinbasePreference ?? 'all'
      }));
    }
    if (options.enableWalletConnect !== false) {
      customConnectors.push(walletConnect(options, appKit, this.caipNetworks));
    }
    if (options.enableInjected !== false) {
      customConnectors.push(injected({
        shimDisconnect: true
      }));
    }
    const emailEnabled = options.features?.email === undefined ? CoreConstantsUtil.DEFAULT_FEATURES.email : options.features?.email;
    const socialsEnabled = options.features?.socials ? options.features?.socials?.length > 0 : CoreConstantsUtil.DEFAULT_FEATURES.socials;
    if (emailEnabled || socialsEnabled) {
      customConnectors.push(authConnector({
        chains: this.wagmiChains,
        options: {
          projectId: options.projectId,
          enableAuthLogger: options.enableAuthLogger
        }
      }));
    }
    customConnectors.forEach(connector => {
      const cnctr = this.wagmiConfig._internal.connectors.setup(connector);
      this.wagmiConfig._internal.connectors.setState(prev => [...prev, cnctr]);
    });
  }
  async signMessage(params) {
    try {
      const signature = await signMessage(this.wagmiConfig, {
        message: params.message,
        account: params.address
      });
      return {
        signature
      };
    } catch (error) {
      throw new Error('WagmiAdapter:signMessage - Sign message failed');
    }
  }
  async sendTransaction(params) {
    const {
      chainId
    } = getAccount(this.wagmiConfig);
    const txParams = {
      account: params.address,
      to: params.to,
      value: params.value,
      gas: params.gas,
      gasPrice: params.gasPrice,
      data: params.data,
      chainId,
      type: 'legacy'
    };
    await prepareTransactionRequest(this.wagmiConfig, txParams);
    const tx = await wagmiSendTransaction(this.wagmiConfig, txParams);
    await waitForTransactionReceipt(this.wagmiConfig, {
      hash: tx,
      timeout: 25000
    });
    return {
      hash: tx
    };
  }
  async writeContract(params) {
    const {
      caipNetwork,
      ...data
    } = params;
    const chainId = Number(NetworkUtil.caipNetworkIdToNumber(caipNetwork.caipNetworkId));
    const tx = await wagmiWriteContract(this.wagmiConfig, {
      chain: this.wagmiChains?.[chainId],
      chainId,
      address: data.tokenAddress,
      account: data.fromAddress,
      abi: data.abi,
      functionName: data.method,
      args: data.args
    });
    return {
      hash: tx
    };
  }
  async getEnsAddress(params) {
    const {
      name,
      caipNetwork
    } = params;
    try {
      if (!this.wagmiConfig) {
        throw new Error('networkControllerClient:getApprovedCaipNetworksData - wagmiConfig is undefined');
      }
      let ensName = false;
      let wcName = false;
      if (isReownName(name)) {
        wcName = (await WcHelpersUtil.resolveReownName(name)) || false;
      }
      if (caipNetwork.id === 1) {
        ensName = await wagmiGetEnsAddress(this.wagmiConfig, {
          name: normalize(name),
          chainId: caipNetwork.id
        });
      }
      return {
        address: ensName || wcName || false
      };
    } catch {
      return {
        address: false
      };
    }
  }
  async estimateGas(params) {
    try {
      const result = await wagmiEstimateGas(this.wagmiConfig, {
        account: params.address,
        to: params.to,
        data: params.data,
        type: 'legacy'
      });
      return {
        gas: result
      };
    } catch (error) {
      throw new Error('WagmiAdapter:estimateGas - error estimating gas');
    }
  }
  parseUnits(params) {
    return parseUnits(params.value, params.decimals);
  }
  formatUnits(params) {
    return formatUnits(params.value, params.decimals);
  }
  addWagmiConnector(connector, options) {
    if (connector.id === CommonConstantsUtil.CONNECTOR_ID.AUTH || connector.id === CommonConstantsUtil.CONNECTOR_ID.WALLET_CONNECT) {
      return;
    }
    this.addConnector({
      id: connector.id,
      explorerId: PresetsUtil.ConnectorExplorerIds[connector.id],
      imageUrl: options?.connectorImages?.[connector.id] ?? connector.icon,
      name: PresetsUtil.ConnectorNamesMap[connector.id] ?? connector.name,
      imageId: PresetsUtil.ConnectorImageIds[connector.id],
      type: PresetsUtil.ConnectorTypesMap[connector.type] ?? 'EXTERNAL',
      info: connector.id === CommonConstantsUtil.CONNECTOR_ID.INJECTED ? undefined : {
        rdns: connector.id
      },
      chain: this.namespace,
      chains: []
    });
  }
  syncConnectors(options, appKit) {
    this.addWagmiConnectors(options, appKit);
    this.wagmiConfig.connectors.forEach(connector => this.addWagmiConnector(connector, options));
    watchConnectors(this.wagmiConfig, {
      onChange: connectors => connectors.forEach(connector => this.addWagmiConnector(connector, options))
    });
  }
  async syncConnection(params) {
    const {
      id
    } = params;
    const connections = getConnections(this.wagmiConfig);
    const connection = connections.find(c => c.connector.id === id);
    const connector = this.getWagmiConnector(id);
    const provider = await connector?.getProvider();
    return {
      chainId: Number(connection?.chainId),
      address: connection?.accounts[0],
      provider,
      type: connection?.connector.type,
      id: connection?.connector.id
    };
  }
  async connectWalletConnect(onUri, chainId) {
    const connector = this.getWagmiConnector('walletConnect');
    if (!connector) {
      throw new Error('UniversalAdapter:connectWalletConnect - connector not found');
    }
    const provider = await connector.getProvider();
    if (!this.caipNetworks || !provider) {
      throw new Error('UniversalAdapter:connectWalletConnect - caipNetworks or provider is undefined');
    }
    provider.on('display_uri', uri => {
      onUri(uri);
    });
    await connect(this.wagmiConfig, {
      connector,
      chainId: chainId ? Number(chainId) : undefined
    });
  }
  async connect(params) {
    const {
      id,
      provider,
      type,
      info,
      chainId
    } = params;
    const connector = this.getWagmiConnector(id);
    if (!connector) {
      throw new Error('connectionControllerClient:connectExternal - connector is undefined');
    }
    if (provider && info && connector.id === CommonConstantsUtil.CONNECTOR_ID.EIP6963) {
      connector.setEip6963Wallet?.({
        provider,
        info
      });
    }
    const res = await connect(this.wagmiConfig, {
      connector,
      chainId: chainId ? Number(chainId) : undefined
    });
    return {
      address: res.accounts[0],
      chainId: res.chainId,
      provider: provider,
      type: type,
      id
    };
  }
  async reconnect(params) {
    const {
      id
    } = params;
    const connector = this.getWagmiConnector(id);
    if (!connector) {
      throw new Error('connectionControllerClient:connectExternal - connector is undefined');
    }
    await reconnect(this.wagmiConfig, {
      connectors: [connector]
    });
  }
  async getBalance(params) {
    const caipNetwork = this.caipNetworks?.find(network => network.id === params.chainId);
    if (caipNetwork && this.wagmiConfig) {
      const chainId = Number(params.chainId);
      const balance = await getBalance(this.wagmiConfig, {
        address: params.address,
        chainId,
        token: params.tokens?.[caipNetwork.caipNetworkId]?.address
      });
      return {
        balance: balance.formatted,
        symbol: balance.symbol
      };
    }
    return {
      balance: '',
      symbol: ''
    };
  }
  async getProfile(params) {
    const chainId = params.chainId;
    const profileName = await getEnsName(this.wagmiConfig, {
      address: params.address,
      chainId
    });
    if (profileName) {
      const profileImage = await getEnsAvatar(this.wagmiConfig, {
        name: profileName,
        chainId
      });
      return {
        profileName,
        profileImage: profileImage ?? undefined
      };
    }
    return {
      profileName: undefined,
      profileImage: undefined
    };
  }
  getWalletConnectProvider() {
    return this.getWagmiConnector('walletConnect')?.['provider'];
  }
  async disconnect() {
    const connections = getConnections(this.wagmiConfig);
    await Promise.all(connections.map(async connection => {
      const connector = this.getWagmiConnector(connection.connector.id);
      if (connector) {
        await wagmiDisconnect(this.wagmiConfig, {
          connector
        });
      }
    }));
  }
  async switchNetwork(params) {
    await switchChain(this.wagmiConfig, {
      chainId: params.caipNetwork.id
    });
  }
  async getCapabilities(params) {
    if (!this.wagmiConfig) {
      throw new Error('connectionControllerClient:getCapabilities - wagmiConfig is undefined');
    }
    const connections = getConnections(this.wagmiConfig);
    const connection = connections[0];
    const connector = connection ? this.getWagmiConnector(connection.connector.id) : null;
    if (!connector) {
      throw new Error('connectionControllerClient:getCapabilities - connector is undefined');
    }
    const provider = await connector.getProvider();
    if (!provider) {
      throw new Error('connectionControllerClient:getCapabilities - provider is undefined');
    }
    const walletCapabilitiesString = provider.session?.sessionProperties?.['capabilities'];
    if (walletCapabilitiesString) {
      const walletCapabilities = parseWalletCapabilities(walletCapabilitiesString);
      const accountCapabilities = walletCapabilities[params];
      if (accountCapabilities) {
        return accountCapabilities;
      }
    }
    return await provider.request({
      method: 'wallet_getCapabilities',
      params: [params]
    });
  }
  async grantPermissions(params) {
    if (!this.wagmiConfig) {
      throw new Error('connectionControllerClient:grantPermissions - wagmiConfig is undefined');
    }
    const connections = getConnections(this.wagmiConfig);
    const connection = connections[0];
    const connector = connection ? this.getWagmiConnector(connection.connector.id) : null;
    if (!connector) {
      throw new Error('connectionControllerClient:grantPermissions - connector is undefined');
    }
    const provider = await connector.getProvider();
    if (!provider) {
      throw new Error('connectionControllerClient:grantPermissions - provider is undefined');
    }
    return provider.request({
      method: 'wallet_grantPermissions',
      params
    });
  }
  async revokePermissions(params) {
    if (!this.wagmiConfig) {
      throw new Error('connectionControllerClient:revokePermissions - wagmiConfig is undefined');
    }
    const connections = getConnections(this.wagmiConfig);
    const connection = connections[0];
    const connector = connection ? this.getWagmiConnector(connection.connector.id) : null;
    if (!connector) {
      throw new Error('connectionControllerClient:revokePermissions - connector is undefined');
    }
    const provider = await connector.getProvider();
    if (!provider) {
      throw new Error('connectionControllerClient:revokePermissions - provider is undefined');
    }
    return provider.request({
      method: 'wallet_revokePermissions',
      params
    });
  }
}
