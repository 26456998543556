/* eslint-disable max-depth */
import { ConstantsUtil as CoreConstantsUtil, SIWXUtil } from '@reown/appkit-core';
import { AccountController, BlockchainApiController, ConnectionController, ConnectorController, CoreHelperUtil, EventsController, ModalController, ChainController, PublicStateController, ThemeController, SnackController, RouterController, EnsController, OptionsController, AssetUtil, ApiController, AlertController, StorageUtil } from '@reown/appkit-core';
import { setColorTheme, setThemeVariables } from '@reown/appkit-ui';
import { NetworkUtil, ConstantsUtil, ParseUtil } from '@reown/appkit-common';
import { UniversalAdapter, UniversalAdapter as UniversalAdapterClient } from './universal-adapter/client.js';
import { CaipNetworksUtil, ErrorUtil, HelpersUtil, LoggerUtil, ConstantsUtil as UtilConstantsUtil } from '@reown/appkit-utils';
import { W3mFrameHelpers, W3mFrameRpcConstants } from '@reown/appkit-wallet';
import { ProviderUtil } from './store/ProviderUtil.js';
import UniversalProvider from '@walletconnect/universal-provider';
import { W3mFrameProviderSingleton } from './auth-provider/W3MFrameProviderSingleton.js';
import { WcHelpersUtil } from './utils/HelpersUtil.js';
import { WalletUtil } from '@reown/appkit-scaffold-ui/utils';
// -- Export Controllers -------------------------------------------------------
export { AccountController };
// -- Constants ----------------------------------------- //
const OPTIONAL_METHODS = ['eth_accounts', 'eth_requestAccounts', 'eth_sendRawTransaction', 'eth_sign', 'eth_signTransaction', 'eth_signTypedData', 'eth_signTypedData_v3', 'eth_signTypedData_v4', 'eth_sendTransaction', 'personal_sign', 'wallet_switchEthereumChain', 'wallet_addEthereumChain', 'wallet_getPermissions', 'wallet_requestPermissions', 'wallet_registerOnboarding', 'wallet_watchAsset', 'wallet_scanQRCode',
// EIP-5792
'wallet_getCallsStatus', 'wallet_sendCalls', 'wallet_getCapabilities',
// EIP-7715
'wallet_grantPermissions', 'wallet_revokePermissions'];
// -- Helpers -------------------------------------------------------------------
let isInitialized = false;
// -- Client --------------------------------------------------------------------
export class AppKit {
  constructor(options) {
    this.chainNamespaces = [];
    this.initPromise = undefined;
    this.reportedAlertErrors = {};
    this.setStatus = (status, chain) => {
      StorageUtil.setConnectionStatus(status);
      AccountController.setStatus(status, chain);
    };
    this.getIsConnectedState = () => Boolean(ChainController.state.activeCaipAddress);
    this.setAllAccounts = (addresses, chain) => {
      AccountController.setAllAccounts(addresses, chain);
      OptionsController.setHasMultipleAddresses(addresses?.length > 1);
    };
    this.addAddressLabel = (address, label, chain) => {
      AccountController.addAddressLabel(address, label, chain);
    };
    this.removeAddressLabel = (address, chain) => {
      AccountController.removeAddressLabel(address, chain);
    };
    this.getCaipAddress = chainNamespace => {
      if (ChainController.state.activeChain === chainNamespace || !chainNamespace) {
        return ChainController.state.activeCaipAddress;
      }
      return ChainController.getAccountProp('caipAddress', chainNamespace);
    };
    this.getAddressByChainNamespace = chainNamespace => ChainController.getAccountProp('address', chainNamespace);
    this.getAddress = chainNamespace => {
      if (ChainController.state.activeChain === chainNamespace || !chainNamespace) {
        return AccountController.state.address;
      }
      return ChainController.getAccountProp('address', chainNamespace);
    };
    this.getProvider = () => AccountController.state.provider;
    this.getPreferredAccountType = () => AccountController.state.preferredAccountType;
    this.setCaipAddress = (caipAddress, chain) => {
      AccountController.setCaipAddress(caipAddress, chain);
    };
    this.setProvider = (provider, chain) => {
      AccountController.setProvider(provider, chain);
    };
    this.setBalance = (balance, balanceSymbol, chain) => {
      AccountController.setBalance(balance, balanceSymbol, chain);
    };
    this.setProfileName = (profileName, chain) => {
      AccountController.setProfileName(profileName, chain);
    };
    this.setProfileImage = (profileImage, chain) => {
      AccountController.setProfileImage(profileImage, chain);
    };
    this.setUser = user => {
      AccountController.setUser(user);
    };
    this.resetAccount = chain => {
      AccountController.resetAccount(chain);
    };
    this.setCaipNetwork = caipNetwork => {
      ChainController.setActiveCaipNetwork(caipNetwork);
    };
    this.getCaipNetwork = chainNamespace => {
      if (chainNamespace) {
        return ChainController.getRequestedCaipNetworks(chainNamespace).filter(c => c.chainNamespace === chainNamespace)?.[0];
      }
      return ChainController.state.activeCaipNetwork || this.defaultCaipNetwork;
    };
    this.getCaipNetworkId = () => {
      const network = this.getCaipNetwork();
      if (network) {
        return network.id;
      }
      return undefined;
    };
    this.getCaipNetworks = namespace => ChainController.getRequestedCaipNetworks(namespace);
    this.getActiveChainNamespace = () => ChainController.state.activeChain;
    this.setRequestedCaipNetworks = (requestedCaipNetworks, chain) => {
      ChainController.setRequestedCaipNetworks(requestedCaipNetworks, chain);
    };
    this.getApprovedCaipNetworkIds = () => ChainController.getAllApprovedCaipNetworkIds();
    this.setApprovedCaipNetworksData = namespace => ChainController.setApprovedCaipNetworksData(namespace);
    this.resetNetwork = namespace => {
      ChainController.resetNetwork(namespace);
    };
    this.setConnectors = connectors => {
      const allConnectors = [...ConnectorController.getConnectors(), ...connectors];
      ConnectorController.setConnectors(allConnectors);
    };
    this.addConnector = connector => {
      ConnectorController.addConnector(connector);
    };
    this.getConnectors = () => ConnectorController.getConnectors();
    this.resetWcConnection = () => {
      ConnectionController.resetWcConnection();
    };
    this.fetchIdentity = request => BlockchainApiController.fetchIdentity(request);
    this.setAddressExplorerUrl = (addressExplorerUrl, chain) => {
      AccountController.setAddressExplorerUrl(addressExplorerUrl, chain);
    };
    this.setSmartAccountDeployed = (isDeployed, chain) => {
      AccountController.setSmartAccountDeployed(isDeployed, chain);
    };
    this.setConnectedWalletInfo = (connectedWalletInfo, chain) => {
      AccountController.setConnectedWalletInfo(connectedWalletInfo, chain);
    };
    this.setSmartAccountEnabledNetworks = (smartAccountEnabledNetworks, chain) => {
      ChainController.setSmartAccountEnabledNetworks(smartAccountEnabledNetworks, chain);
    };
    this.setPreferredAccountType = (preferredAccountType, chain) => {
      AccountController.setPreferredAccountType(preferredAccountType, chain);
    };
    this.getReownName = address => EnsController.getNamesForAddress(address);
    this.setEIP6963Enabled = enabled => {
      OptionsController.setEIP6963Enabled(enabled);
    };
    this.setClientId = clientId => {
      BlockchainApiController.setClientId(clientId);
    };
    this.getConnectorImage = connector => AssetUtil.getConnectorImage(connector);
    this.handleUnsafeRPCRequest = () => {
      if (this.isOpen()) {
        // If we are on the modal but there is no transaction stack, close the modal
        if (this.isTransactionStackEmpty()) {
          return;
        }
        // Check if we need to replace or redirect
        this.redirect('ApproveTransaction');
      } else {
        // If called from outside the modal, open ApproveTransaction
        this.open({
          view: 'ApproveTransaction'
        });
      }
    };
    this.options = options;
    this.version = options.sdkVersion;
    this.caipNetworks = this.extendCaipNetworks(options);
    this.chainNamespaces = [...new Set(this.caipNetworks?.map(caipNetwork => caipNetwork.chainNamespace))];
    this.defaultCaipNetwork = this.extendDefaultCaipNetwork(options);
    this.chainAdapters = this.createAdapters(options.adapters);
    this.initialize(options);
  }
  static getInstance() {
    return this.instance;
  }
  async initialize(options) {
    this.initControllers(options);
    await this.initChainAdapters();
    await this.injectModalUi();
    await this.syncExistingConnection();
    const {
      ...optionsCopy
    } = options;
    delete optionsCopy.adapters;
    EventsController.sendEvent({
      type: 'track',
      event: 'INITIALIZE',
      properties: {
        ...optionsCopy,
        networks: options.networks.map(n => n.id),
        siweConfig: {
          options: options.siweConfig?.options || {}
        }
      }
    });
    PublicStateController.set({
      initialized: true
    });
  }
  // -- Public -------------------------------------------------------------------
  async open(options) {
    await this.injectModalUi();
    if (options?.uri && this.universalAdapter) {
      ConnectionController.setUri(options.uri);
    }
    ModalController.open(options);
  }
  async close() {
    await this.injectModalUi();
    ModalController.close();
  }
  setLoading(loading) {
    ModalController.setLoading(loading);
  }
  // -- Adapter Methods ----------------------------------------------------------
  getError() {
    return '';
  }
  getChainId() {
    return ChainController.state.activeCaipNetwork?.id;
  }
  switchNetwork(appKitNetwork) {
    const network = this.caipNetworks?.find(n => n.id === appKitNetwork.id);
    if (!network) {
      AlertController.open(ErrorUtil.ALERT_ERRORS.SWITCH_NETWORK_NOT_FOUND, 'error');
      return;
    }
    ChainController.switchActiveNetwork(network);
  }
  getWalletProvider() {
    return ChainController.state.activeChain ? ProviderUtil.state.providers[ChainController.state.activeChain] : null;
  }
  getWalletProviderType() {
    return ChainController.state.activeChain ? ProviderUtil.state.providerIds[ChainController.state.activeChain] : null;
  }
  subscribeProviders(callback) {
    return ProviderUtil.subscribeProviders(callback);
  }
  getThemeMode() {
    return ThemeController.state.themeMode;
  }
  getThemeVariables() {
    return ThemeController.state.themeVariables;
  }
  setThemeMode(themeMode) {
    ThemeController.setThemeMode(themeMode);
    setColorTheme(ThemeController.state.themeMode);
  }
  setTermsConditionsUrl(termsConditionsUrl) {
    OptionsController.setTermsConditionsUrl(termsConditionsUrl);
  }
  setPrivacyPolicyUrl(privacyPolicyUrl) {
    OptionsController.setPrivacyPolicyUrl(privacyPolicyUrl);
  }
  setThemeVariables(themeVariables) {
    ThemeController.setThemeVariables(themeVariables);
    setThemeVariables(ThemeController.state.themeVariables);
  }
  subscribeTheme(callback) {
    return ThemeController.subscribe(callback);
  }
  getWalletInfo() {
    return AccountController.state.connectedWalletInfo;
  }
  subscribeAccount(callback) {
    function updateVal() {
      callback({
        allAccounts: AccountController.state.allAccounts,
        caipAddress: ChainController.state.activeCaipAddress,
        address: CoreHelperUtil.getPlainAddress(ChainController.state.activeCaipAddress),
        isConnected: Boolean(ChainController.state.activeCaipAddress),
        status: AccountController.state.status,
        embeddedWalletInfo: {
          user: AccountController.state.user,
          accountType: AccountController.state.preferredAccountType,
          isSmartAccountDeployed: Boolean(AccountController.state.smartAccountDeployed)
        }
      });
    }
    ChainController.subscribe(updateVal);
    AccountController.subscribe(updateVal);
  }
  subscribeNetwork(callback) {
    return ChainController.subscribe(({
      activeCaipNetwork
    }) => {
      callback({
        caipNetwork: activeCaipNetwork,
        chainId: activeCaipNetwork?.id,
        caipNetworkId: activeCaipNetwork?.caipNetworkId
      });
    });
  }
  subscribeWalletInfo(callback) {
    return AccountController.subscribeKey('connectedWalletInfo', callback);
  }
  subscribeShouldUpdateToAddress(callback) {
    AccountController.subscribeKey('shouldUpdateToAddress', callback);
  }
  subscribeCaipNetworkChange(callback) {
    ChainController.subscribeKey('activeCaipNetwork', callback);
  }
  getState() {
    return PublicStateController.state;
  }
  subscribeState(callback) {
    return PublicStateController.subscribe(callback);
  }
  showErrorMessage(message) {
    SnackController.showError(message);
  }
  showSuccessMessage(message) {
    SnackController.showSuccess(message);
  }
  getEvent() {
    return {
      ...EventsController.state
    };
  }
  subscribeEvents(callback) {
    return EventsController.subscribe(callback);
  }
  replace(route) {
    RouterController.replace(route);
  }
  redirect(route) {
    RouterController.push(route);
  }
  popTransactionStack(cancel) {
    RouterController.popTransactionStack(cancel);
  }
  isOpen() {
    return ModalController.state.open;
  }
  isTransactionStackEmpty() {
    return RouterController.state.transactionStack.length === 0;
  }
  isTransactionShouldReplaceView() {
    return RouterController.state.transactionStack[RouterController.state.transactionStack.length - 1]?.replace;
  }
  updateFeatures(newFeatures) {
    OptionsController.setFeatures(newFeatures);
  }
  updateOptions(newOptions) {
    const currentOptions = OptionsController.state || {};
    const updatedOptions = {
      ...currentOptions,
      ...newOptions
    };
    OptionsController.setOptions(updatedOptions);
  }
  setConnectMethodsOrder(connectMethodsOrder) {
    OptionsController.setConnectMethodsOrder(connectMethodsOrder);
  }
  setWalletFeaturesOrder(walletFeaturesOrder) {
    OptionsController.setWalletFeaturesOrder(walletFeaturesOrder);
  }
  setCollapseWallets(collapseWallets) {
    OptionsController.setCollapseWallets(collapseWallets);
  }
  setSocialsOrder(socialsOrder) {
    OptionsController.setSocialsOrder(socialsOrder);
  }
  async disconnect() {
    await ConnectionController.disconnect();
  }
  getConnectMethodsOrder() {
    return WalletUtil.getConnectOrderMethod(OptionsController.state.features, ConnectorController.getConnectors());
  }
  /**
   * Removes an adapter from the AppKit.
   * @param namespace - The namespace of the adapter to remove.
   */
  removeAdapter(namespace) {
    const isConnected = this.getIsConnectedState();
    const adapter = this.getAdapter(namespace);
    if (!adapter || !this.chainAdapters || isConnected) {
      return;
    }
    const newCaipNetworks = this.caipNetworks?.filter(network => network.chainNamespace !== namespace);
    ChainController.removeAdapter(namespace);
    ConnectorController.removeAdapter(namespace);
    this.chainNamespaces = this.chainNamespaces.filter(n => n !== namespace);
    this.caipNetworks = newCaipNetworks;
    adapter.removeAllEventListeners();
    Reflect.deleteProperty(this.chainAdapters, namespace);
  }
  /**
   * Adds an adapter to the AppKit.
   * @param adapter - The adapter instance.
   * @param networks - The list of networks that this adapter supports / uses.
   */
  addAdapter(adapter, networks) {
    const namespace = adapter.namespace;
    if (!this.connectionControllerClient || !this.networkControllerClient) {
      return;
    }
    if (!this.chainAdapters || !namespace) {
      return;
    }
    const extendedAdapterNetworks = this.extendCaipNetworks({
      ...this.options,
      networks
    });
    this.caipNetworks = [...(this.caipNetworks || []), ...extendedAdapterNetworks];
    this.createAdapter(adapter);
    this.initChainAdapter(namespace);
    ChainController.addAdapter(adapter, {
      connectionControllerClient: this.connectionControllerClient,
      networkControllerClient: this.networkControllerClient
    }, extendedAdapterNetworks);
  }
  // -- Private ------------------------------------------------------------------
  initializeOptionsController(options) {
    OptionsController.setDebug(options.debug !== false);
    if (!options.projectId) {
      AlertController.open(ErrorUtil.ALERT_ERRORS.PROJECT_ID_NOT_CONFIGURED, 'error');
      return;
    }
    // On by default
    OptionsController.setEnableWalletConnect(options.enableWalletConnect !== false);
    OptionsController.setEnableWalletGuide(options.enableWalletGuide !== false);
    OptionsController.setEnableWallets(options.enableWallets !== false);
    OptionsController.setEIP6963Enabled(options.enableEIP6963 !== false);
    OptionsController.setEnableAuthLogger(options.enableAuthLogger !== false);
    OptionsController.setSdkVersion(options.sdkVersion);
    OptionsController.setProjectId(options.projectId);
    OptionsController.setEnableEmbedded(options.enableEmbedded);
    OptionsController.setAllWallets(options.allWallets);
    OptionsController.setIncludeWalletIds(options.includeWalletIds);
    OptionsController.setExcludeWalletIds(options.excludeWalletIds);
    OptionsController.setFeaturedWalletIds(options.featuredWalletIds);
    OptionsController.setTokens(options.tokens);
    OptionsController.setTermsConditionsUrl(options.termsConditionsUrl);
    OptionsController.setPrivacyPolicyUrl(options.privacyPolicyUrl);
    OptionsController.setCustomWallets(options.customWallets);
    OptionsController.setFeatures(options.features);
    OptionsController.setAllowUnsupportedChain(options.allowUnsupportedChain);
    const defaultMetaData = this.getDefaultMetaData();
    if (!options.metadata && defaultMetaData) {
      options.metadata = defaultMetaData;
    }
    OptionsController.setMetadata(options.metadata);
    OptionsController.setDisableAppend(options.disableAppend);
    OptionsController.setEnableEmbedded(options.enableEmbedded);
    OptionsController.setSIWX(options.siwx);
    const evmAdapter = options.adapters?.find(adapter => adapter.namespace === ConstantsUtil.CHAIN.EVM);
    // Set the SIWE client for EVM chains
    if (evmAdapter) {
      if (options.siweConfig) {
        if (options.siwx) {
          throw new Error('Cannot set both `siweConfig` and `siwx` options');
        }
        OptionsController.setSIWX(options.siweConfig.mapToSIWX());
      }
    }
  }
  initializeThemeController(options) {
    if (options.themeMode) {
      ThemeController.setThemeMode(options.themeMode);
    }
    if (options.themeVariables) {
      ThemeController.setThemeVariables(options.themeVariables);
    }
  }
  initializeChainController(options) {
    if (!this.connectionControllerClient || !this.networkControllerClient) {
      throw new Error('ConnectionControllerClient and NetworkControllerClient must be set');
    }
    ChainController.initialize(options.adapters ?? [], this.caipNetworks, {
      connectionControllerClient: this.connectionControllerClient,
      networkControllerClient: this.networkControllerClient
    });
    const network = this.getDefaultNetwork();
    if (network) {
      ChainController.setActiveCaipNetwork(network);
    }
  }
  initControllers(options) {
    this.initializeOptionsController(options);
    this.initializeChainController(options);
    this.initializeThemeController(options);
    if (options.excludeWalletIds) {
      ApiController.initializeExcludedWalletRdns({
        ids: options.excludeWalletIds
      });
    }
  }
  getDefaultMetaData() {
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
      return {
        name: document.getElementsByTagName('title')?.[0]?.textContent || '',
        description: document.querySelector('meta[property="og:description"]')?.content || '',
        url: window.location.origin,
        icons: [document.querySelector('link[rel~="icon"]')?.href || '']
      };
    }
    return null;
  }
  setUnsupportedNetwork(chainId) {
    const namespace = this.getActiveChainNamespace();
    if (namespace) {
      ChainController.setActiveCaipNetwork({
        id: chainId,
        caipNetworkId: `${namespace}:${chainId}`,
        name: 'Unknown Network',
        chainNamespace: namespace,
        nativeCurrency: {
          name: '',
          decimals: 0,
          symbol: ''
        },
        rpcUrls: {
          default: {
            http: []
          }
        }
      });
    }
  }
  extendCaipNetworks(options) {
    const extendedNetworks = CaipNetworksUtil.extendCaipNetworks(options.networks, {
      customNetworkImageUrls: options.chainImages,
      projectId: options.projectId
    });
    return extendedNetworks;
  }
  extendDefaultCaipNetwork(options) {
    const defaultNetwork = options.networks.find(n => n.id === options.defaultNetwork?.id);
    const extendedNetwork = defaultNetwork ? CaipNetworksUtil.extendCaipNetwork(defaultNetwork, {
      customNetworkImageUrls: options.chainImages,
      projectId: options.projectId
    }) : undefined;
    return extendedNetwork;
  }
  createClients() {
    this.connectionControllerClient = {
      connectWalletConnect: async onUri => {
        const adapter = this.getAdapter(ChainController.state.activeChain);
        this.universalProvider?.on('display_uri', onUri);
        this.setClientId((await this.universalProvider?.client?.core?.crypto?.getClientId()) || null);
        let isAuthenticated = false;
        if (this.universalProvider) {
          const chains = this.caipNetworks?.map(network => network.caipNetworkId) || [];
          isAuthenticated = await SIWXUtil.universalProviderAuthenticate({
            universalProvider: this.universalProvider,
            chains,
            methods: OPTIONAL_METHODS
          });
        }
        if (isAuthenticated) {
          this.close();
        } else {
          await adapter?.connectWalletConnect(onUri, this.getCaipNetwork()?.id);
          StorageUtil.setConnectedNamespaces([...ChainController.state.chains.keys()]);
        }
        await this.syncWalletConnectAccount();
      },
      connectExternal: async ({
        id,
        info,
        type,
        provider,
        chain,
        caipNetwork
      }) => {
        const activeChain = ChainController.state.activeChain;
        if (chain && chain !== activeChain && !caipNetwork) {
          const toConnectNetwork = this.caipNetworks?.find(network => network.chainNamespace === chain);
          if (toConnectNetwork) {
            this.setCaipNetwork(toConnectNetwork);
          }
        }
        const chainToUse = chain || activeChain;
        const adapter = this.getAdapter(chainToUse);
        if (!adapter) {
          throw new Error('Adapter not found');
        }
        const res = await adapter.connect({
          id,
          info,
          type,
          provider,
          chainId: caipNetwork?.id || this.getCaipNetwork()?.id,
          rpcUrl: caipNetwork?.rpcUrls?.default?.http?.[0] || this.getCaipNetwork()?.rpcUrls?.default?.http?.[0]
        });
        StorageUtil.addConnectedNamespace(chainToUse);
        if (res) {
          this.syncProvider({
            ...res,
            chainNamespace: chainToUse
          });
          await this.syncAccount({
            ...res,
            chainNamespace: chainToUse
          });
          const {
            accounts
          } = await adapter.getAccounts({
            namespace: chainToUse,
            id
          });
          this.setAllAccounts(accounts, chainToUse);
        }
        if (!this.caipNetworks?.some(network => network.id === res?.chainId)) {
          if (res?.chainId) {
            this.setUnsupportedNetwork(res.chainId);
          }
        }
      },
      reconnectExternal: async ({
        id,
        info,
        type,
        provider
      }) => {
        const namespace = ChainController.state.activeChain;
        const adapter = this.getAdapter(namespace);
        if (adapter?.reconnect) {
          await adapter?.reconnect({
            id,
            info,
            type,
            provider,
            chainId: this.getCaipNetwork()?.id
          });
          StorageUtil.addConnectedNamespace(namespace);
        }
      },
      disconnect: async () => {
        const namespace = ChainController.state.activeChain;
        const adapter = this.getAdapter(namespace);
        const provider = ProviderUtil.getProvider(namespace);
        const providerType = ProviderUtil.state.providerIds[namespace];
        await adapter?.disconnect({
          provider,
          providerType
        });
        StorageUtil.removeConnectedNamespace(namespace);
        ProviderUtil.resetChain(namespace);
        this.setUser(undefined);
        this.setStatus('disconnected', namespace);
      },
      checkInstalled: ids => {
        if (!ids) {
          return Boolean(window.ethereum);
        }
        return ids.some(id => Boolean(window.ethereum?.[String(id)]));
      },
      signMessage: async message => {
        const adapter = this.getAdapter(ChainController.state.activeChain);
        const result = await adapter?.signMessage({
          message,
          address: AccountController.state.address,
          provider: ProviderUtil.getProvider(ChainController.state.activeChain)
        });
        return result?.signature || '';
      },
      sendTransaction: async args => {
        if (args.chainNamespace === ConstantsUtil.CHAIN.EVM) {
          const adapter = this.getAdapter(ChainController.state.activeChain);
          const provider = ProviderUtil.getProvider(ChainController.state.activeChain);
          const result = await adapter?.sendTransaction({
            ...args,
            provider
          });
          return result?.hash || '';
        }
        return '';
      },
      estimateGas: async args => {
        if (args.chainNamespace === ConstantsUtil.CHAIN.EVM) {
          const adapter = this.getAdapter(ChainController.state.activeChain);
          const provider = ProviderUtil.getProvider(ChainController.state.activeChain);
          const caipNetwork = this.getCaipNetwork();
          if (!caipNetwork) {
            throw new Error('CaipNetwork is undefined');
          }
          const result = await adapter?.estimateGas({
            ...args,
            provider,
            caipNetwork
          });
          return result?.gas || 0n;
        }
        return 0n;
      },
      getEnsAvatar: async () => {
        const adapter = this.getAdapter(ChainController.state.activeChain);
        const result = await adapter?.getProfile({
          address: AccountController.state.address,
          chainId: Number(this.getCaipNetwork()?.id)
        });
        return result?.profileImage || false;
      },
      getEnsAddress: async name => {
        const adapter = this.getAdapter(ChainController.state.activeChain);
        const caipNetwork = this.getCaipNetwork();
        if (!caipNetwork) {
          return false;
        }
        const result = await adapter?.getEnsAddress({
          name,
          caipNetwork
        });
        return result?.address || false;
      },
      writeContract: async args => {
        const adapter = this.getAdapter(ChainController.state.activeChain);
        const caipNetwork = this.getCaipNetwork();
        const caipAddress = this.getCaipAddress();
        const provider = ProviderUtil.getProvider(ChainController.state.activeChain);
        if (!caipNetwork || !caipAddress) {
          throw new Error('CaipNetwork or CaipAddress is undefined');
        }
        const result = await adapter?.writeContract({
          ...args,
          caipNetwork,
          provider,
          caipAddress
        });
        return result?.hash;
      },
      parseUnits: (value, decimals) => {
        const adapter = this.getAdapter(ChainController.state.activeChain);
        return adapter?.parseUnits({
          value,
          decimals
        }) ?? 0n;
      },
      formatUnits: (value, decimals) => {
        const adapter = this.getAdapter(ChainController.state.activeChain);
        return adapter?.formatUnits({
          value,
          decimals
        }) ?? '0';
      },
      getCapabilities: async params => {
        const adapter = this.getAdapter(ChainController.state.activeChain);
        await adapter?.getCapabilities(params);
      },
      grantPermissions: async params => {
        const adapter = this.getAdapter(ChainController.state.activeChain);
        return await adapter?.grantPermissions(params);
      },
      revokePermissions: async params => {
        const adapter = this.getAdapter(ChainController.state.activeChain);
        if (adapter?.revokePermissions) {
          return await adapter.revokePermissions(params);
        }
        return '0x';
      }
    };
    this.networkControllerClient = {
      switchCaipNetwork: async caipNetwork => {
        if (!caipNetwork) {
          return;
        }
        if (AccountController.state.address && caipNetwork.chainNamespace === ChainController.state.activeChain) {
          const adapter = this.getAdapter(ChainController.state.activeChain);
          const provider = ProviderUtil.getProvider(ChainController.state.activeChain);
          const providerType = ProviderUtil.state.providerIds[ChainController.state.activeChain];
          await adapter?.switchNetwork({
            caipNetwork,
            provider,
            providerType
          });
          this.setCaipNetwork(caipNetwork);
          await this.syncAccount({
            address: AccountController.state.address,
            chainId: caipNetwork.id,
            chainNamespace: caipNetwork.chainNamespace
          });
        } else if (AccountController.state.address) {
          const providerType = ProviderUtil.state.providerIds[ChainController.state.activeChain];
          if (providerType === UtilConstantsUtil.CONNECTOR_TYPE_AUTH) {
            try {
              ChainController.state.activeChain = caipNetwork.chainNamespace;
              await this.connectionControllerClient?.connectExternal?.({
                id: ConstantsUtil.CONNECTOR_ID.AUTH,
                provider: this.authProvider,
                chain: caipNetwork.chainNamespace,
                chainId: caipNetwork.id,
                type: UtilConstantsUtil.CONNECTOR_TYPE_AUTH,
                caipNetwork
              });
            } catch (error) {
              const adapter = this.getAdapter(caipNetwork.chainNamespace);
              await adapter?.switchNetwork({
                caipNetwork,
                provider: this.authProvider,
                providerType
              });
            }
          } else if (providerType === 'WALLET_CONNECT') {
            this.setCaipNetwork(caipNetwork);
            this.syncWalletConnectAccount();
          } else {
            this.setCaipNetwork(caipNetwork);
            const address = this.getAddressByChainNamespace(caipNetwork.chainNamespace);
            if (address) {
              this.syncAccount({
                address,
                chainId: caipNetwork.id,
                chainNamespace: caipNetwork.chainNamespace
              });
            }
          }
        } else {
          this.setCaipNetwork(caipNetwork);
        }
      },
      // eslint-disable-next-line @typescript-eslint/require-await
      getApprovedCaipNetworksData: async () => {
        const providerType = ProviderUtil.state.providerIds[ChainController.state.activeChain];
        if (providerType === UtilConstantsUtil.CONNECTOR_TYPE_WALLET_CONNECT) {
          const namespaces = this.universalProvider?.session?.namespaces;
          return {
            /*
             * MetaMask Wallet only returns 1 namespace in the session object. This makes it imposible
             * to switch to other networks. Setting supportsAllNetworks to true for MetaMask Wallet
             * will make it possible to switch to other networks.
             */
            supportsAllNetworks: this.universalProvider?.session?.peer?.metadata.name === 'MetaMask Wallet',
            approvedCaipNetworkIds: this.getChainsFromNamespaces(namespaces)
          };
        }
        return {
          supportsAllNetworks: true,
          approvedCaipNetworkIds: []
        };
      }
    };
    ConnectionController.setClient(this.connectionControllerClient);
  }
  setupAuthConnectorListeners(provider) {
    provider.onRpcRequest(request => {
      if (W3mFrameHelpers.checkIfRequestExists(request)) {
        if (!W3mFrameHelpers.checkIfRequestIsSafe(request)) {
          this.handleUnsafeRPCRequest();
        }
      } else {
        this.open();
        // eslint-disable-next-line no-console
        console.error(W3mFrameRpcConstants.RPC_METHOD_NOT_ALLOWED_MESSAGE, {
          method: request.method
        });
        setTimeout(() => {
          this.showErrorMessage(W3mFrameRpcConstants.RPC_METHOD_NOT_ALLOWED_UI_MESSAGE);
        }, 300);
        provider.rejectRpcRequests();
      }
    });
    provider.onRpcError(() => {
      const isModalOpen = this.isOpen();
      if (isModalOpen) {
        if (this.isTransactionStackEmpty()) {
          this.close();
        } else {
          this.popTransactionStack(true);
        }
      }
    });
    provider.onRpcSuccess((_, request) => {
      const isSafeRequest = W3mFrameHelpers.checkIfRequestIsSafe(request);
      if (isSafeRequest) {
        return;
      }
      if (this.isTransactionStackEmpty()) {
        this.close();
        if (AccountController.state.address && ChainController.state.activeCaipNetwork?.id) {
          this.updateBalance();
        }
      } else {
        this.popTransactionStack();
        if (AccountController.state.address && ChainController.state.activeCaipNetwork?.id) {
          this.updateBalance();
        }
      }
    });
    provider.onNotConnected(() => {
      const namespace = ChainController.state.activeChain;
      const connectorId = StorageUtil.getConnectedConnectorId(namespace);
      const isConnectedWithAuth = connectorId === ConstantsUtil.CONNECTOR_ID.AUTH;
      if (isConnectedWithAuth) {
        this.setCaipAddress(undefined, namespace);
        this.setLoading(false);
      }
    });
    provider.onIsConnected(() => {
      provider.connect();
      StorageUtil.addConnectedNamespace(ChainController.state.activeChain);
    });
    provider.onConnect(async user => {
      const namespace = ChainController.state.activeChain;
      this.syncProvider({
        type: UtilConstantsUtil.CONNECTOR_TYPE_AUTH,
        provider,
        id: ConstantsUtil.CONNECTOR_ID.AUTH,
        chainNamespace: namespace
      });
      // To keep backwards compatibility, eip155 chainIds are numbers and not actual caipChainIds
      const caipAddress = namespace === ConstantsUtil.CHAIN.EVM ? `eip155:${user.chainId}:${user.address}` : `${user.chainId}:${user.address}`;
      this.setSmartAccountDeployed(Boolean(user.smartAccountDeployed), namespace);
      if (!HelpersUtil.isLowerCaseMatch(user.address, AccountController.state.address)) {
        this.syncIdentity({
          address: user.address,
          chainId: user.chainId,
          chainNamespace: namespace
        });
      }
      this.setCaipAddress(caipAddress, namespace);
      this.setUser({
        ...(AccountController.state.user || {}),
        email: user.email
      });
      const preferredAccountType = user.preferredAccountType || 'eoa';
      this.setPreferredAccountType(preferredAccountType, namespace);
      const userAccounts = user.accounts?.map(account => CoreHelperUtil.createAccount(namespace, account.address, namespace === ConstantsUtil.CHAIN.EVM ? account.type : 'eoa'));
      this.setAllAccounts(userAccounts || [CoreHelperUtil.createAccount(namespace, user.address, preferredAccountType)], namespace);
      await provider.getSmartAccountEnabledNetworks();
      this.setLoading(false);
    });
    provider.onSocialConnected(({
      userName
    }) => {
      this.setUser({
        ...(AccountController.state.user || {}),
        username: userName
      });
    });
    provider.onGetSmartAccountEnabledNetworks(networks => {
      this.setSmartAccountEnabledNetworks(networks, ChainController.state.activeChain);
    });
    provider.onSetPreferredAccount(({
      address,
      type
    }) => {
      if (!address) {
        return;
      }
      this.setPreferredAccountType(type, ChainController.state.activeChain);
    });
  }
  async syncAuthConnector(provider) {
    this.setLoading(true);
    const isLoginEmailUsed = provider.getLoginEmailUsed();
    this.setLoading(isLoginEmailUsed);
    if (isLoginEmailUsed) {
      this.setStatus('connecting', ChainController.state.activeChain);
    }
    const email = provider.getEmail();
    const username = provider.getUsername();
    this.setUser({
      ...(AccountController.state?.user || {}),
      username,
      email
    });
    this.setupAuthConnectorListeners(provider);
    const {
      isConnected
    } = await provider.isConnected();
    const namespace = StorageUtil.getActiveNamespace();
    if (namespace) {
      if (isConnected && this.connectionControllerClient?.connectExternal) {
        await this.connectionControllerClient?.connectExternal({
          id: ConstantsUtil.CONNECTOR_ID.AUTH,
          info: {
            name: ConstantsUtil.CONNECTOR_ID.AUTH
          },
          type: UtilConstantsUtil.CONNECTOR_TYPE_AUTH,
          provider,
          chainId: ChainController.state.activeCaipNetwork?.id,
          chain: namespace
        });
        this.setStatus('connected', namespace);
      } else if (StorageUtil.getConnectedConnectorId(namespace) === ConstantsUtil.CONNECTOR_ID.AUTH) {
        this.setStatus('disconnected', namespace);
        StorageUtil.removeConnectedNamespace(namespace);
      }
    }
    this.setLoading(false);
  }
  listenWalletConnect() {
    if (this.universalProvider) {
      this.universalProvider.on('disconnect', () => {
        this.chainNamespaces.forEach(namespace => {
          this.resetAccount(namespace);
        });
        ConnectionController.resetWcConnection();
      });
      this.universalProvider.on('chainChanged', chainId => {
        const caipNetwork = this.caipNetworks?.find(
        // eslint-disable-next-line eqeqeq
        c => c.chainNamespace === ChainController.state.activeChain && c.id == chainId);
        const currentCaipNetwork = this.getCaipNetwork();
        if (!caipNetwork) {
          this.setUnsupportedNetwork(chainId);
          return;
        }
        if (!currentCaipNetwork || currentCaipNetwork?.id !== caipNetwork?.id) {
          this.setCaipNetwork(caipNetwork);
        }
      });
      this.universalProvider.on('session_event', callbackData => {
        if (WcHelpersUtil.isSessionEventData(callbackData)) {
          const {
            name,
            data
          } = callbackData.params.event;
          if (name === 'accountsChanged' && Array.isArray(data) && CoreHelperUtil.isCaipAddress(data[0])) {
            this.syncAccount(ParseUtil.parseCaipAddress(data[0]));
          }
        }
      });
    }
  }
  listenAdapter(chainNamespace) {
    const adapter = this.getAdapter(chainNamespace);
    if (!adapter) {
      return;
    }
    const connectionStatus = StorageUtil.getConnectionStatus();
    if (connectionStatus === 'connected') {
      this.setStatus('connecting', chainNamespace);
    } else {
      this.setStatus(connectionStatus, chainNamespace);
    }
    adapter.on('switchNetwork', ({
      address,
      chainId
    }) => {
      if (chainId && this.caipNetworks?.find(n => n.id === chainId)) {
        if (ChainController.state.activeChain === chainNamespace && address) {
          this.syncAccount({
            address,
            chainId,
            chainNamespace
          });
        } else if (ChainController.state.activeChain === chainNamespace && AccountController.state.address) {
          this.syncAccount({
            address: AccountController.state.address,
            chainId,
            chainNamespace
          });
        }
      } else {
        this.setUnsupportedNetwork(chainId);
      }
    });
    adapter.on('disconnect', this.disconnect.bind(this));
    adapter.on('pendingTransactions', () => {
      const address = AccountController.state.address;
      const activeCaipNetwork = ChainController.state.activeCaipNetwork;
      if (!address || !activeCaipNetwork?.id) {
        return;
      }
      this.updateBalance();
    });
    adapter.on('accountChanged', ({
      address,
      chainId
    }) => {
      if (ChainController.state.activeChain === chainNamespace && chainId) {
        this.syncAccount({
          address,
          chainId,
          chainNamespace
        });
      } else if (ChainController.state.activeChain === chainNamespace && ChainController.state.activeCaipNetwork?.id) {
        this.syncAccount({
          address,
          chainId: ChainController.state.activeCaipNetwork?.id,
          chainNamespace
        });
      }
    });
  }
  updateBalance() {
    const adapter = this.getAdapter(ChainController.state.activeChain);
    if (adapter) {
      adapter.getBalance({
        address: AccountController.state.address,
        chainId: ChainController.state.activeCaipNetwork?.id,
        caipNetwork: this.getCaipNetwork(),
        tokens: this.options.tokens
      });
    }
  }
  getChainsFromNamespaces(namespaces = {}) {
    return Object.values(namespaces).flatMap(namespace => {
      const chains = namespace.chains || [];
      const accountsChains = namespace.accounts.map(account => {
        const {
          chainId,
          chainNamespace
        } = ParseUtil.parseCaipAddress(account);
        return `${chainNamespace}:${chainId}`;
      });
      return Array.from(new Set([...chains, ...accountsChains]));
    });
  }
  async syncWalletConnectAccount() {
    const adapter = this.getAdapter(ChainController.state.activeChain);
    this.chainNamespaces.forEach(async chainNamespace => {
      const namespaceAccounts = this.universalProvider?.session?.namespaces?.[chainNamespace]?.accounts || [];
      // We try and find the address for this network in the session object.
      const activeChainId = ChainController.state.activeCaipNetwork?.id;
      const sessionAddress = namespaceAccounts.find(account => {
        const {
          chainId
        } = ParseUtil.parseCaipAddress(account);
        return chainId === activeChainId?.toString();
      }) || namespaceAccounts[0];
      if (sessionAddress) {
        const caipAddress = ParseUtil.validateCaipAddress(sessionAddress);
        const {
          chainId,
          address
        } = ParseUtil.parseCaipAddress(caipAddress);
        ProviderUtil.setProviderId(chainNamespace, UtilConstantsUtil.CONNECTOR_TYPE_WALLET_CONNECT);
        if (this.caipNetworks && ChainController.state.activeCaipNetwork && adapter?.namespace !== ConstantsUtil.CHAIN.EVM) {
          const provider = adapter?.getWalletConnectProvider({
            caipNetworks: this.caipNetworks,
            provider: this.universalProvider,
            activeCaipNetwork: ChainController.state.activeCaipNetwork
          });
          ProviderUtil.setProvider(chainNamespace, provider);
        } else {
          ProviderUtil.setProvider(chainNamespace, this.universalProvider);
        }
        StorageUtil.setConnectedConnectorId(chainNamespace, ConstantsUtil.CONNECTOR_ID.WALLET_CONNECT);
        StorageUtil.addConnectedNamespace(chainNamespace);
        if (adapter?.adapterType === 'wagmi') {
          try {
            await adapter?.connect({
              id: 'walletConnect',
              type: 'WALLET_CONNECT',
              chainId: ChainController.state.activeCaipNetwork?.id
            });
          } catch (error) {
            /**
             * Handle edge case where wagmi detects existing connection but lacks to complete UniversalProvider instance.
             * Connection attempt fails due to already connected state - reconnect to restore provider state.
             */
            if (adapter?.reconnect) {
              adapter?.reconnect({
                id: 'walletConnect',
                type: 'WALLET_CONNECT'
              });
            }
          }
        }
        this.syncWalletConnectAccounts(chainNamespace);
        await this.syncAccount({
          address,
          chainId,
          chainNamespace
        });
      }
    });
    await ChainController.setApprovedCaipNetworksData(ChainController.state.activeChain);
  }
  syncWalletConnectAccounts(chainNamespace) {
    const addresses = this.universalProvider?.session?.namespaces?.[chainNamespace]?.accounts?.map(account => {
      const {
        address
      } = ParseUtil.parseCaipAddress(account);
      return address;
    }).filter((address, index, self) => self.indexOf(address) === index);
    if (addresses) {
      this.setAllAccounts(addresses.map(address => CoreHelperUtil.createAccount(chainNamespace, address, chainNamespace === 'bip122' ? 'payment' : 'eoa')), chainNamespace);
    }
  }
  syncProvider({
    type,
    provider,
    id,
    chainNamespace
  }) {
    ProviderUtil.setProviderId(chainNamespace, type);
    ProviderUtil.setProvider(chainNamespace, provider);
    StorageUtil.setConnectedConnectorId(chainNamespace, id);
  }
  async syncAccount(params) {
    const {
      address,
      chainId,
      chainNamespace
    } = params;
    const {
      namespace: activeNamespace,
      chainId: activeChainId
    } = StorageUtil.getActiveNetworkProps();
    const chainIdToUse = chainId || activeChainId;
    // Only update state when needed
    if (!HelpersUtil.isLowerCaseMatch(address, AccountController.state.address)) {
      this.setCaipAddress(`${chainNamespace}:${chainId}:${address}`, chainNamespace);
      await this.syncIdentity({
        address,
        chainId,
        chainNamespace
      });
    }
    this.setStatus('connected', chainNamespace);
    if (chainIdToUse && chainNamespace === activeNamespace) {
      let caipNetwork = this.caipNetworks?.find(n => n.id.toString() === chainIdToUse.toString());
      let fallbackCaipNetwork = this.caipNetworks?.find(n => n.chainNamespace === chainNamespace);
      const shouldSupportsAllNetworks = ChainController.getNetworkProp('supportsAllNetworks', chainNamespace);
      if (!shouldSupportsAllNetworks) {
        // Connection can be requested for a chain that is not supported by the wallet so we need to use approved networks here
        const caipNetworkIds = this.getApprovedCaipNetworkIds() || [];
        const caipNetworkId = caipNetworkIds.find(id => ParseUtil.parseCaipNetworkId(id)?.chainId === chainIdToUse.toString());
        const fallBackCaipNetworkId = caipNetworkIds.find(id => ParseUtil.parseCaipNetworkId(id)?.chainNamespace === chainNamespace);
        caipNetwork = this.caipNetworks?.find(n => n.caipNetworkId === caipNetworkId);
        fallbackCaipNetwork = this.caipNetworks?.find(n => n.caipNetworkId === fallBackCaipNetworkId);
      }
      const network = caipNetwork || fallbackCaipNetwork;
      this.setCaipNetwork(network);
      this.syncConnectedWalletInfo(chainNamespace);
      await this.syncBalance({
        address,
        chainId: network.id,
        chainNamespace
      });
    }
  }
  async syncBalance(params) {
    const caipNetwork = NetworkUtil.getNetworksByNamespace(this.caipNetworks, params.chainNamespace).find(n => n.id.toString() === params.chainId.toString());
    if (!caipNetwork) {
      return;
    }
    if (caipNetwork.testnet) {
      this.setBalance('0.00', caipNetwork.nativeCurrency.symbol, caipNetwork.chainNamespace);
      return;
    }
    const balances = await AccountController.fetchTokenBalance(() => this.setBalance('0.00', caipNetwork.nativeCurrency.symbol, caipNetwork.chainNamespace));
    const balance = balances.find(b => b.chainId === `${params.chainNamespace}:${params.chainId}` && b.symbol === caipNetwork.nativeCurrency.symbol);
    this.setBalance(balance?.quantity?.numeric || '0.00', caipNetwork.nativeCurrency.symbol, params.chainNamespace);
  }
  syncConnectedWalletInfo(chainNamespace) {
    const connectorId = StorageUtil.getConnectedConnectorId(chainNamespace);
    const providerType = ProviderUtil.state.providerIds[chainNamespace];
    if (providerType === UtilConstantsUtil.CONNECTOR_TYPE_ANNOUNCED || providerType === UtilConstantsUtil.CONNECTOR_TYPE_INJECTED) {
      if (connectorId) {
        const connector = this.getConnectors().find(c => c.id === connectorId);
        if (connector) {
          const {
            info,
            name,
            imageUrl
          } = connector;
          const icon = imageUrl || this.getConnectorImage(connector);
          this.setConnectedWalletInfo({
            name,
            icon,
            ...info
          }, chainNamespace);
        }
      }
    } else if (providerType === UtilConstantsUtil.CONNECTOR_TYPE_WALLET_CONNECT) {
      const provider = ProviderUtil.getProvider(chainNamespace);
      if (provider?.session) {
        this.setConnectedWalletInfo({
          ...provider.session.peer.metadata,
          name: provider.session.peer.metadata.name,
          icon: provider.session.peer.metadata.icons?.[0]
        }, chainNamespace);
      }
    } else if (connectorId) {
      if (connectorId === ConstantsUtil.CONNECTOR_ID.COINBASE) {
        const connector = this.getConnectors().find(c => c.id === ConstantsUtil.CONNECTOR_ID.COINBASE);
        this.setConnectedWalletInfo({
          name: 'Coinbase Wallet',
          icon: this.getConnectorImage(connector)
        }, chainNamespace);
      }
      this.setConnectedWalletInfo({
        name: connectorId
      }, chainNamespace);
    }
  }
  async syncIdentity({
    address,
    chainId,
    chainNamespace
  }) {
    const activeCaipNetwork = this.caipNetworks?.find(n => n.caipNetworkId === `${chainNamespace}:${chainId}`);
    if (chainNamespace !== ConstantsUtil.CHAIN.EVM || activeCaipNetwork?.testnet) {
      return;
    }
    try {
      const {
        name,
        avatar
      } = await this.fetchIdentity({
        address
      });
      this.setProfileName(name, chainNamespace);
      this.setProfileImage(avatar, chainNamespace);
      if (!name) {
        await this.syncReownName(address, chainNamespace);
        const adapter = this.getAdapter(chainNamespace);
        const result = await adapter?.getProfile({
          address,
          chainId: Number(chainId)
        });
        if (result?.profileName) {
          this.setProfileName(result.profileName, chainNamespace);
          if (result.profileImage) {
            this.setProfileImage(result.profileImage, chainNamespace);
          }
        } else {
          await this.syncReownName(address, chainNamespace);
          this.setProfileImage(null, chainNamespace);
        }
      }
    } catch {
      if (chainId === 1) {
        await this.syncReownName(address, chainNamespace);
      } else {
        await this.syncReownName(address, chainNamespace);
        this.setProfileImage(null, chainNamespace);
      }
    }
  }
  async syncReownName(address, chainNamespace) {
    try {
      const registeredWcNames = await this.getReownName(address);
      if (registeredWcNames[0]) {
        const wcName = registeredWcNames[0];
        this.setProfileName(wcName.name, chainNamespace);
      } else {
        this.setProfileName(null, chainNamespace);
      }
    } catch {
      this.setProfileName(null, chainNamespace);
    }
  }
  async syncAdapterConnection(namespace) {
    const adapter = this.getAdapter(namespace);
    const connectorId = StorageUtil.getConnectedConnectorId(namespace);
    const caipNetwork = this.getCaipNetwork();
    try {
      if (!adapter || !connectorId) {
        throw new Error(`Adapter or connectorId not found for namespace ${namespace}`);
      }
      const connection = await adapter?.syncConnection({
        namespace,
        id: connectorId,
        chainId: caipNetwork?.id,
        rpcUrl: caipNetwork?.rpcUrls?.default?.http?.[0]
      });
      if (connection) {
        const accounts = await adapter?.getAccounts({
          namespace,
          id: connectorId
        });
        if (accounts && accounts.accounts.length > 0) {
          this.setAllAccounts(accounts.accounts, namespace);
        } else {
          this.setAllAccounts([CoreHelperUtil.createAccount(namespace, connection.address, 'eoa')], namespace);
        }
        this.syncProvider({
          ...connection,
          chainNamespace: namespace
        });
        await this.syncAccount({
          ...connection,
          chainNamespace: namespace
        });
        this.setStatus('connected', namespace);
      } else {
        this.setStatus('disconnected', namespace);
      }
    } catch (e) {
      StorageUtil.deleteConnectedConnectorId(namespace);
      this.setStatus('disconnected', namespace);
    }
  }
  async syncNamespaceConnection(namespace) {
    try {
      const connectorId = StorageUtil.getConnectedConnectorId(namespace);
      const isEmailUsed = this.authProvider?.getLoginEmailUsed();
      if (isEmailUsed) {
        return;
      }
      this.setStatus('connecting', namespace);
      switch (connectorId) {
        case ConstantsUtil.CONNECTOR_ID.WALLET_CONNECT:
          await this.syncWalletConnectAccount();
          break;
        case ConstantsUtil.CONNECTOR_ID.AUTH:
          // Handled during initialization of adapters' auth provider
          break;
        default:
          await this.syncAdapterConnection(namespace);
      }
    } catch (err) {
      console.warn("AppKit couldn't sync existing connection", err);
      StorageUtil.deleteConnectedConnectorId(namespace);
      this.setStatus('disconnected', namespace);
    }
  }
  async syncExistingConnection() {
    await Promise.allSettled(this.chainNamespaces.map(namespace => this.syncNamespaceConnection(namespace)));
  }
  getAdapter(namespace) {
    return this.chainAdapters?.[namespace];
  }
  createUniversalProvider() {
    if (!this.universalProviderInitPromise && CoreHelperUtil.isClient() && this.options?.projectId) {
      this.universalProviderInitPromise = this.initializeUniversalAdapter();
    }
    return this.universalProviderInitPromise;
  }
  handleAlertError(error) {
    const matchedUniversalProviderError = Object.entries(ErrorUtil.UniversalProviderErrors).find(([, {
      message
    }]) => error.message.includes(message));
    const [errorKey, errorValue] = matchedUniversalProviderError ?? [];
    const {
      message,
      alertErrorKey
    } = errorValue ?? {};
    if (errorKey && message && !this.reportedAlertErrors[errorKey]) {
      const alertError = ErrorUtil.ALERT_ERRORS[alertErrorKey];
      if (alertError) {
        AlertController.open(alertError, 'error');
        this.reportedAlertErrors[errorKey] = true;
      }
    }
  }
  async initializeUniversalAdapter() {
    const logger = LoggerUtil.createLogger((error, ...args) => {
      if (error) {
        this.handleAlertError(error);
      }
      // eslint-disable-next-line no-console
      console.error(...args);
    });
    const universalProviderOptions = {
      projectId: this.options?.projectId,
      metadata: {
        name: this.options?.metadata ? this.options?.metadata.name : '',
        description: this.options?.metadata ? this.options?.metadata.description : '',
        url: this.options?.metadata ? this.options?.metadata.url : '',
        icons: this.options?.metadata ? this.options?.metadata.icons : ['']
      },
      logger
    };
    OptionsController.setUsingInjectedUniversalProvider(Boolean(this.options?.universalProvider));
    this.universalProvider = this.options.universalProvider ?? (await UniversalProvider.init(universalProviderOptions));
    this.listenWalletConnect();
  }
  async getUniversalProvider() {
    if (!this.universalProvider) {
      try {
        await this.createUniversalProvider();
      } catch (error) {
        throw new Error('AppKit:getUniversalProvider - Cannot create provider');
      }
    }
    return this.universalProvider;
  }
  createAuthProvider() {
    const isEmailEnabled = this.options?.features?.email === undefined ? CoreConstantsUtil.DEFAULT_FEATURES.email : this.options?.features?.email;
    const isSocialsEnabled = this.options?.features?.socials ? this.options?.features?.socials?.length > 0 : CoreConstantsUtil.DEFAULT_FEATURES.socials;
    const isAuthEnabled = isEmailEnabled || isSocialsEnabled;
    if (!this.authProvider && this.options?.projectId && isAuthEnabled) {
      this.authProvider = W3mFrameProviderSingleton.getInstance({
        projectId: this.options.projectId,
        enableLogger: this.options.enableAuthLogger,
        onTimeout: () => {
          AlertController.open(ErrorUtil.ALERT_ERRORS.SOCIALS_TIMEOUT, 'error');
        }
      });
      this.subscribeState(val => {
        if (!val.open) {
          this.authProvider?.rejectRpcRequests();
        }
      });
      this.syncAuthConnector(this.authProvider);
    }
  }
  async createUniversalProviderForAdapter(chainNamespace) {
    await this.getUniversalProvider();
    if (this.universalProvider) {
      this.chainAdapters?.[chainNamespace]?.setUniversalProvider?.(this.universalProvider);
    }
  }
  createAuthProviderForAdapter(chainNamespace) {
    this.createAuthProvider();
    if (this.authProvider) {
      this.chainAdapters?.[chainNamespace]?.setAuthProvider?.(this.authProvider);
    }
  }
  createAdapter(blueprint) {
    if (!blueprint) {
      return;
    }
    const namespace = blueprint.namespace;
    if (!namespace) {
      return;
    }
    this.createClients();
    const adapterBlueprint = blueprint;
    adapterBlueprint.namespace = namespace;
    adapterBlueprint.construct({
      namespace,
      projectId: this.options?.projectId,
      networks: this.caipNetworks
    });
    if (!this.chainNamespaces.includes(namespace)) {
      this.chainNamespaces.push(namespace);
    }
    if (this.chainAdapters) {
      this.chainAdapters[namespace] = adapterBlueprint;
    }
  }
  createAdapters(blueprints) {
    this.createClients();
    return this.chainNamespaces.reduce((adapters, namespace) => {
      const blueprint = blueprints?.find(b => b.namespace === namespace);
      if (blueprint) {
        adapters[namespace] = blueprint;
        adapters[namespace].namespace = namespace;
        adapters[namespace].construct({
          namespace,
          projectId: this.options?.projectId,
          networks: this.caipNetworks
        });
      } else {
        adapters[namespace] = new UniversalAdapter({
          namespace,
          networks: this.caipNetworks
        });
      }
      return adapters;
      // eslint-disable-next-line @typescript-eslint/prefer-reduce-type-parameter
    }, {});
  }
  onConnectors(chainNamespace) {
    const adapter = this.getAdapter(chainNamespace);
    adapter?.on('connectors', this.setConnectors.bind(this));
  }
  async initChainAdapter(namespace) {
    this.onConnectors(namespace);
    this.listenAdapter(namespace);
    this.chainAdapters?.[namespace].syncConnectors(this.options, this);
    await this.createUniversalProviderForAdapter(namespace);
    this.createAuthProviderForAdapter(namespace);
  }
  async initChainAdapters() {
    await Promise.all(this.chainNamespaces.map(async namespace => {
      await this.initChainAdapter(namespace);
    }));
  }
  getDefaultNetwork() {
    const previousNetwork = StorageUtil.getActiveCaipNetworkId();
    const caipNetwork = previousNetwork && this.caipNetworks?.length ? this.caipNetworks.find(n => n.caipNetworkId === previousNetwork) : undefined;
    const network = caipNetwork || this.defaultCaipNetwork || this.caipNetworks?.[0];
    return network;
  }
  async injectModalUi() {
    if (!this.initPromise && !isInitialized && CoreHelperUtil.isClient()) {
      isInitialized = true;
      this.initPromise = new Promise(async resolve => {
        await Promise.all([import('@reown/appkit-ui'), import('@reown/appkit-scaffold-ui/w3m-modal')]);
        const modal = document.createElement('w3m-modal');
        if (!OptionsController.state.disableAppend && !OptionsController.state.enableEmbedded) {
          document.body.insertAdjacentElement('beforeend', modal);
        }
        resolve();
      });
    }
    return this.initPromise;
  }
}
